export const sectoresOptions = [
  {
    value: "Admisión-y-Boletaje",
    text: "Admisión y Boletaje",
  },
  {
    value: "Alimentos-y-Bebidas",
    text: "Alimentos y Bebidas",
  },
  {
    value: "Industrial",
    text: "Industrial",
  },
  {
    value: "Retail",
    text: "Retail",
  },
  {
    value: "Salud,-Belleza-y-Hogar",
    text: "Salud, Belleza y Hogar",
  },
  {
    value: "Vinos-y-Licores",
    text: "Vinos y Licores",
  },
];
