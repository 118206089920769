export const cantidadPiezasOptions = [
  {
    value: "3000-a-5000",
    text: "3000 a 5000",
  },
  {
    value: "5001-a-10-000",
    text: "5001 a 10 000",
  },
  {
    value: "10-001-a-50-000",
    text: "10 001 a 50 000",
  },
  {
    value: "más-de-50-000",
    text: "más de 50 000",
  },
];
